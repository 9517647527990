import { Session } from './../../models/session.model';
import { createAction, props } from '@ngrx/store';
import { LoginWithOneTimeTokenResult, SwitchResult } from './session.reducer';

export const actionPrefix = '[Session]';

export const loadSessionSuccess = createAction(actionPrefix + ' Load Session Success', props<{ data: any }>());

export const loadSessionFailure = createAction(actionPrefix + ' Load Session Failure', props<{ error: any }>());

export const login = createAction(actionPrefix + ' Login');
/**
 * this action is dispatched by auth.service on succeeded login
 * several effects subscribe on it to reload data related to current session (user, contract..)
 */
export const loginSuccess = createAction(actionPrefix + ' Login Success', props<{ data: Session }>());

export const sessionValid = createAction(actionPrefix + ' Session Valid');
export const sessionInvalid = createAction(actionPrefix + ' Session Invalid');

/**
 * this action is dispatched by auth.service on succeeded logout
 * this is primary used in a meta reducer, defined in root-store.module to reset the state on logout success
 */
export const logoutSuccess = createAction(actionPrefix + ' Logout Success');

/**
 * this action is dispatched in app.component on a 401 on any endpoint (if it is not whitelisted)
 * the module.component listenst for this action to do a session re-check
 */
export const authFailure = createAction(actionPrefix + ' Auth failure');

export const jumpToPartner = createAction(actionPrefix + ' JumpIntoPartner');


export const switchProject = createAction(actionPrefix + ' SwitchProject', props<{ target_project_uuid: string }>());
export const switchProjectSuccess = createAction(actionPrefix + ' SwitchProject Success', props<{ target_project_uuid: string, data: SwitchResult }>());
export const switchProjectFailure = createAction(actionPrefix + ' SwitchProject Failure', props<{ target_project_uuid: string, error: Error }>());

export const switchContract = createAction(actionPrefix + ' SwitchContract', props<{ target_project_uuid: string }>());
export const switchContractSuccess = createAction(actionPrefix + ' SwitchContract Success', props<{ target_project_uuid: string, data: SwitchResult }>());
export const switchContractFailure = createAction(actionPrefix + ' SwitchContract Failure', props<{ target_project_uuid: string, error: Error }>());

export const loginWithOneTimeToken = createAction(actionPrefix + ' LoginWithOneTimeToken', props<{ token: string }>());
export const loginWithOneTimeTokenSuccess = createAction(actionPrefix + ' LoginWithOneTimeToken Success', props<{ data: LoginWithOneTimeTokenResult }>());
export const loginWithOneTimeTokenFailure = createAction(actionPrefix + ' LoginWithOneTimeToken Failure', props<{ error: Error }>());
