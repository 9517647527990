import { createReducer, on } from '@ngrx/store';
import { Session } from './../../models/session.model';
import * as SessionActions from './session.actions';
import { HttpErrorResponse } from '@angular/common/http';

export const sessionFeatureKey = 'session';

export const GS_AUTH_LOCALSTORAGE_KEY_NG = 'gs_auth_NG';
export const GS_AUTH_LOCALSTORAGE_KEY = 'gs_auth';

export interface MiddlewareSession {
  project_uuid: string;
  token: string;
  token_validation?: boolean;
  user_uuid: string;
}

export interface SwitchResult {
  contract_uuid?: string;
  partner_uuid?: string;
  project_uuid?: string;
  token?: string;
  user_uuid?: string;
};

export interface LoginWithOneTimeTokenResult {
  contract_uuid: string;
  partner_uuid: string;
  project_uuid: string;
  user_uuid: string;
  token: string;
}

export interface GS_AUTH {
  token: string;
  user: string;
  project: string;
  contract: string;
  partner: string;
}

export interface State {
  data?: Session;
  valid: boolean;
  loading: boolean;
  loaded: boolean;
  isProjectSwitching: boolean;
  isContractSwitching: boolean;
  isLoggingInWithOneTimeToken: boolean;
  isLoggingInWithOneTimeTokenDone: boolean;
  error?: Error | HttpErrorResponse | undefined;
  loginError?: Error | HttpErrorResponse | undefined;
  logoutError?: Error | HttpErrorResponse | undefined;
  validationError?: Error | HttpErrorResponse | undefined;
}

export const initialState: State = {
  valid: false,
  loading: false,
  loaded: false,
  isProjectSwitching: false,
  isContractSwitching: false,
  isLoggingInWithOneTimeToken: false,
  isLoggingInWithOneTimeTokenDone: false
};

export const getAuthData = () => {
  try {
    if (window.localStorage.getItem(GS_AUTH_LOCALSTORAGE_KEY_NG)) {
      return JSON.parse(window?.localStorage?.getItem(GS_AUTH_LOCALSTORAGE_KEY_NG) || '{}');
    }
  } catch (error) {
    return {};
  }
};

initialState.data = getAuthData();

// console.log('Inject Local Storage into initial state! ');

export const reducer = createReducer(
  initialState,

  on(SessionActions.loadSessionSuccess, (state, action): State => {
    return {
      ...state,
      error: undefined,
      data: {
        user_uuid: action.data.user_uuid,
        project_uuid: action.data.project_uuid,
        contract_uuid: action.data.contract_uuid,
        partner_uuid: action.data.partner_uuid,
        token: action.data.token
      },
      valid: true,
      loading: false,
      loaded: true
    };
  }),
  on(
    SessionActions.loadSessionFailure,
    (state, action): State => ({
      ...state,
      error: action.error,
      data: undefined,
      valid: false,
      loading: false,
      loaded: true
    })
  ),
  on(SessionActions.login, (state, action): State => {
    return {
      ...state,
      error: undefined,
      loading: true
    };
  }),
  on(SessionActions.loginSuccess, (state, action): State => {
    return {
      ...state,
      error: undefined,
      data: {
        user_uuid: action.data.user_uuid,
        project_uuid: action.data.project_uuid,
        contract_uuid: action.data.contract_uuid,
        partner_uuid: action.data.partner_uuid,
        token: action.data.token
      },
      valid: true,
      loading: false,
      loaded: true
    };
  }),
  on(SessionActions.logoutSuccess, (state): State => {
    return {
      ...state,
      error: undefined,
      data: undefined,
      valid: false,
      loading: false,
      loaded: false
    };
  }),
  on(SessionActions.switchProject, (state): State => {
    return {
      ...state,
      isProjectSwitching: true
    };
  }),
  on(SessionActions.switchProjectSuccess, SessionActions.switchProjectFailure, (state): State => {
    return {
      ...state,
      isProjectSwitching: false,
      isLoggingInWithOneTimeTokenDone: true
    };
  }),
  on(SessionActions.switchContract, (state): State => {
    return {
      ...state,
      isContractSwitching: true
    };
  }),
  on(/*Never Stop to spin, as we redirect see: SessionActions.switchContractSuccess,*/ SessionActions.switchContractFailure, (state): State => {
    return {
      ...state,
      isContractSwitching: false
    };
  }),
  on(SessionActions.loginWithOneTimeToken, (state): State => {
    return {
      ...state,
      isLoggingInWithOneTimeToken: true,
      isLoggingInWithOneTimeTokenDone: false
    };
  }),
  on(SessionActions.loginWithOneTimeTokenSuccess, (state, action): State => ({
    ...state,
    error: undefined,
    data: {
      user_uuid: action.data.user_uuid,
      project_uuid: action.data.project_uuid,
      contract_uuid: action.data.contract_uuid,
      partner_uuid: action.data.partner_uuid,
      token: action.data.token
    },
    isLoggingInWithOneTimeToken: false,
    isLoggingInWithOneTimeTokenDone: true
  })),
  on(SessionActions.loginWithOneTimeTokenFailure, (state): State => {
    return {
      ...state,
      isLoggingInWithOneTimeToken: false,
      isLoggingInWithOneTimeTokenDone: true
    };
  }),
);
