import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSession from './session.reducer';
import * as _ from 'lodash';
import { Session } from './../../models/session.model';

export const selectSessionState = createFeatureSelector<fromSession.State>(fromSession.sessionFeatureKey);

export const getSessionData = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['data']) as Session);

export const getSessionError = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['error']));

export const isSessionLoading = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['loading']));

export const isSessionLoaded = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['loaded']));

export const isProjectSwitching = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['isProjectSwitching'], false));
export const isContractSwitching = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['isContractSwitching'], false));

export const getCurrentProjectUuid = createSelector(selectSessionState, (state: fromSession.State) => _.get(state, ['data', 'project_uuid']));

export const getCurrentUserUuid = createSelector(selectSessionState, (state: fromSession.State) => {
  return _.get(state, ['data', 'user_uuid']);
});
export const getCurrentPartnerUuid = createSelector(selectSessionState, (state: fromSession.State) => {
  return _.get(state, ['data', 'partner_uuid']);
});

export const getCurrentContractUuid = createSelector(selectSessionState, (state: fromSession.State) => {
  return _.get(state, ['data', 'contract_uuid']);
});

export const isLoggingInWithOneTimeToken = createSelector(selectSessionState, (state: fromSession.State) => {
  return _.get(state, ['isLoggingInWithOneTimeToken'], false)
});

export const isLoggingInWithOneTimeTokenDone = createSelector(selectSessionState, (state: fromSession.State) => {
  return _.get(state, ['isLoggingInWithOneTimeTokenDone'], false)
});
